body {
  --main-bg-color: #FFFFFF;
  --main-color: #010C80;
  --accent-color: #0023FF;
  --btn-success-color: #139b31;
  --btn-success-hover-color: #0b571b;

  --logo-width: 210px;
  --logo-height: 80px;

  --main-font: 'Archivo';

  --fields-border-radius: 5px;
  --input-border-width: 2px;
  --input-height: 2.1rem;
  /*--fields-border-color: #f4243c;*/
  --labels-margin-bottom: 0.8rem;
  --inputs-margin-bottom: 0.4rem;
  --inputs-font-weight: bold;

  margin: 0;
  font-family: -apple-system, var(--main-font),
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  color: var(--main-color);
}

code {
  font-family: Monaco, Consolas, 'Roboto',
    monospace;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1{
  font-size: 2rem;
}
